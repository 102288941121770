<template>
    <v-chip
        class='platform-chip ml-1 pl-1 pr-1'
        dark
        label
        small
        :color='color'
        :outlined='!selected'
    >
        {{ value }}
    </v-chip>
</template>

<script>

const colors = {
    '0': "#439ED5", // "All"
    '1': "#43a047", // Mobile
    '2': "#8e24aa", // Kiosk
    '3': "#d81b60", // Tabletop
    '4': "#f57f17", // Web
    '7': "#0d47a1", // Virtual Kiosk
    '8': "#00796b", // Api
}
export default {
    name: 'platform_label',
    props: {
        value: {
            type: String,
            required: true,
        },
        id: {
            required: true,
        },
        selected: {
            type: Boolean,
            required: false,
            default: true,
        }
    },
    computed: {
        color() {
            if (!this.selected){
                return '#ccc';
            }

            const idStr = ''+this.id;
            if (idStr in colors) {
                return colors[idStr];
            }

            return '#439E00';
        }
    }
};
</script>

<style lang='scss' scoped>
    .platform-chip {
        padding: 0 3px;
        text-transform: capitalize;
    }
</style>
