var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-chip",
    {
      staticClass: "platform-chip ml-1 pl-1 pr-1",
      attrs: {
        dark: "",
        label: "",
        small: "",
        color: _vm.color,
        outlined: !_vm.selected,
      },
    },
    [_vm._v(" " + _vm._s(_vm.value) + " ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }